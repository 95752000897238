import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import logo from "assets/assets/ipams-img/logo.png";
import { AsyncComponent } from "app/Utils";
import { Navbar } from "react-bootstrap";

const EmployerLogin = AsyncComponent(() =>
  import("../container/EmployerLogin")
);
const JobseekerLogin = AsyncComponent(() =>
  import("../container/JobseekerLoginModal")
);

class Header extends Component {
  state = { toogleMenu: true };

  onEmployerLogin = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;

    dispatch({
      type: "MODAL",
      data: {
        isOpen: true,
        title: "EMPLOYER LOGIN",
        modalSize: "modal-md",
        content: <EmployerLogin />,
      },
    });
  };

  jobseekerLogin = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;

    dispatch({
      type: "MODAL",
      data: {
        isOpen: true,
        title: "LOGIN",
        modalSize: "modal-md",
        content: <JobseekerLogin />,
      },
    });
  };

  logout = () => {
    const { dispatch, history } = this.props;
    dispatch({
      type: "SIGN_OUT",
    });
    this.setState({
      toogleMenu: !this.state.toogleMenu,
    });
    history.push("/");
  };

  toogle = (e) => {
    e.preventDefault();
    this.setState({
      toogleMenu: !this.state.toogleMenu,
    });
  };

  componentDidMount() {
    document.addEventListener(
      "click",
      function (event) {
        let windowSize = window.innerWidth;

        if (windowSize <= 991) {
          if (event.target.matches(".nav-link")) {
            document.querySelector(".navbar-toggler").click();
          }
        }
      },
      false
    );
  }

  render() {
    const { isAuthenticated, profile } = this.props;

    return (
      <Fragment>
        <header className="page-head slider-menu-position">
          <div className="rd-navbar-wrap">
            <Navbar
              className="rd-navbar nav-holder rd-navbar-floated rd-navbar-dark rd-navbar-dark-transparent"
              style={{ background: "rgb(0, 0, 0, .7)" }}
              data-md-device-layout="rd-navbar-fixed"
              data-lg-device-layout="rd-navbar-static"
              data-lg-auto-height="true"
              data-md-layout="rd-navbar-fixed"
              data-lg-layout="rd-navbar-static"
              data-lg-stick-up="true"
            >
              <div className="rd-navbar-inner">
                <div className="rd-navbar-panel">
                  <button
                    className="rd-navbar-toggle"
                    data-rd-navbar-toggle=".rd-navbar, .rd-navbar-nav-wrap"
                  >
                    <span></span>
                  </button>
                  <button
                    className="rd-navbar-right-buttons-toggle"
                    data-rd-navbar-toggle=".rd-navbar, .rd-navbar-right-buttons"
                  >
                    <span></span>
                  </button>
                  <div className="rd-navbar-brand">
                    <Link to="/">
                      <img
                        style={{ maxWidth: 170 }}
                        className="nav-logo"
                        src="https://ipams-it.s3.ap-southeast-1.amazonaws.com/CLOUD%20FILES/IPAMS%2050yrs%20v5.png?fbclid=IwAR2IMRFa58YTk3-3Xn6Cr3QN6Jy8OSdH-tJsABtBwEoXqr8pp7klezYRkbo"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
                <div className="rd-navbar-menu-wrap">
                  <div className="rd-navbar-nav-wrap">
                    <div className="rd-navbar-mobile-scroll">
                      <div className="rd-navbar-mobile-brand">
                        {/*<Link to="/"><img className="mobile-nav-logo" src={logo} alt=''/></Link>*/}
                      </div>
                      <ul className="rd-navbar-nav">
                        <li>
                          <Link to="/">
                            <span>Home</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/aboutus">
                            <span>About</span>
                          </Link>
                          <ul className="rd-navbar-dropdown">
                            <li>
                              <Link to="/ipams-chronicles">
                                <span className="text-middle">
                                  IPAMS Chronicles
                                </span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/awards">
                                <span className="text-middle">
                                  Awards & Recognition
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="#">
                            <span>News & Events</span>
                          </Link>
                          <ul className="rd-navbar-dropdown">
                            <li>
                              <Link to="/advisories">
                                <span className="text-middle">Advisories</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/events/today">
                                <span className="text-middle">Events</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/interview">
                                <span className="text-middle">
                                  Interview Schedules
                                </span>
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/social-media">
                            <span>Social Media</span>
                          </Link>
                        </li>
                        {/* <li><Link to="/employers"><span>Employers</span></Link>
                  </li> */}
                        <li>
                          <Link to="/jobseeker">
                            <span>Job Seekers</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/faq">
                            <span>FAQ</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact">
                            <span>Contact Us</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="rd-navbar-right-buttons group d-inline-block">
                    {isAuthenticated ? (
                      <Fragment>
                        <div className="dropdown nav-profile-link">
                          <button
                            className="btn btn-primary text-uppercase dropdown-toggle align-middle-text pt-1 pb-1 float-right"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <div className="profile-photo-thumbnail">
                              <img
                                src={profile.get("photo")}
                                alt="..."
                                className=""
                              />
                            </div>
                            {profile.get("lastname") === "null"
                              ? ""
                              : profile.get("lastname")}
                            {profile.get("suffix") === null
                              ? ""
                              : " " + profile.get("suffix")}
                            ,{" "}
                            {profile.get("firstname") === null
                              ? ""
                              : profile.get("firstname")}{" "}
                            {profile.get("middlename") === null
                              ? ""
                              : profile.get("middlename")}
                          </button>

                          <div
                            className="dropdown-menu applicant-profile-dropdown"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <Link className="nav-link" to="/applicant/profile">
                              PROFILE
                            </Link>
                            <Link
                              className="nav-link"
                              to="/applicant/change-password"
                            >
                              CHANGE PASSWORD
                            </Link>
                            <Link
                              className="nav-link"
                              to="/"
                              onClick={this.logout}
                            >
                              LOGOUT
                            </Link>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Link
                          className="btn btn-primary"
                          to="#"
                          style={{
                            maxHeight: 40 + "px",
                            lineHeight: 22 + "px",
                          }}
                          onClick={this.onEmployerLogin}
                        >
                          <span className="big">EMPLOYER</span>
                        </Link>
                        <div className="text-middle d-xl-inline-block">
                          <p className="big text-muted font-weight-bold">or</p>
                        </div>
                        <Link
                          className="btn btn-primary text-middle"
                          to="#"
                          style={{
                            maxHeight: 40 + "px",
                            lineHeight: 22 + "px",
                          }}
                          onClick={this.jobseekerLogin}
                        >
                          <span className="big">APPLICANT</span>
                        </Link>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </Navbar>
          </div>
        </header>
      </Fragment>
    );
  }
}

const mapPropsToState = (state) => {
  const isAuthenticated = state.auth.get("isAuthenticated");
  const profile = state.auth.get("profile");

  return {
    isAuthenticated,
    profile,
  };
};

export default withRouter(connect(mapPropsToState)(Header));
