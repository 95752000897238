import React from "react";
import gov1 from "assets/images/gov/1.png";
import gov2 from "assets/images/gov/2.png";
import gov3 from "assets/images/gov/3.png";
import gov4 from "assets/images/gov/4.png";
import gov5 from "assets/images/gov/5.png";
import gov6 from "assets/images/gov/6.png";
import gov7 from "assets/images/gov/7.png";
import gov8 from "assets/images/gov/8.png";
import gov9 from "assets/images/gov/9.png";

const GovernmenetSlider = () => {
  return (
    <section className="pt-5 bg-gray-light">
      <div className="title">
        <h1 className="text-center">Government Links</h1>
      </div>

      <div className="slidercontainer">
        <div className="photobanner">
          <a href="https://www.philhealth.gov.ph/" target="_blank">
            <img src={gov1} alt="" />
          </a>
          <a href="https://dmw.gov.ph/" target="_blank">
            <img src={gov2} alt="" />
          </a>
          <a href="https://member.sss.gov.ph/" target="_blank">
            <img src={gov3} alt="" />
          </a>
          <a href="https://clearance.nbi.gov.ph/" target="_blank">
            <img src={gov4} alt="" />
          </a>
          <a href="http://owwa.gov.ph/" target="_blank">
            <img src={gov5} alt="" />
          </a>
          <a href="https://www.tesda.gov.ph/" target="_blank">
            <img src={gov6} alt="" />
          </a>
          <a href="https://www.deped.gov.ph/" target="_blank">
            <img src={gov7} alt="" />
          </a>
          <a href="https://ched.gov.ph/" target="_blank">
            <img src={gov8} alt="" />
          </a>
          <a href="https://peos.dmw.gov.ph/" target="_blank">
            <img src={gov9} alt="" />
          </a>

          <a href="https://www.philhealth.gov.ph/" target="_blank">
            <img src={gov1} alt="" />
          </a>
          <a href="https://dmw.gov.ph/" target="_blank">
            <img src={gov2} alt="" />
          </a>
          <a href="https://member.sss.gov.ph/" target="_blank">
            <img src={gov3} alt="" />
          </a>
          <a href="https://clearance.nbi.gov.ph/" target="_blank">
            <img src={gov4} alt="" />
          </a>
          <a href="http://owwa.gov.ph/" target="_blank">
            <img src={gov5} alt="" />
          </a>
          <a href="https://www.tesda.gov.ph/" target="_blank">
            <img src={gov6} alt="" />
          </a>
          <a href="https://www.deped.gov.ph/" target="_blank">
            <img src={gov7} alt="" />
          </a>
          <a href="https://ched.gov.ph/" target="_blank">
            <img src={gov8} alt="" />
          </a>
          <a href="https://peos.dmw.gov.ph/" target="_blank">
            <img src={gov9} alt="" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default GovernmenetSlider;
