import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import trustedbyfil from "assets/inside-page-assets/about-trustedbyfil.png";
import trustedbygov from "assets/inside-page-assets/about-trustedbygov.png";
import facility from "assets/inside-page-assets/about-facility.png";
import leadTech from "assets/inside-page-assets/about-lead-tech.png";
import multiawards from "assets/inside-page-assets/about-multiawards.png";
import verifiedemployer from "assets/inside-page-assets/about-verifiedemployer.png";
import endtoend from "assets/inside-page-assets/about-endtoend.png";

class WhyPeopleChooseUs extends Component {
  render() {
    return (
      <Fragment>
        <div className="row justify-content-sm-center text-lg-left">
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={trustedbyfil}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              TRUSTED BY FILIPINOS; 100% NO FEE CHARGING COMPANY
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={trustedbygov}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              TRUSTED BY THE PHILIPPINE GOVERNMENT
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={facility}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              BEST-IN-CLASS FACILITIES FOR SMALL TO LARGE-SCALE RECRUITMENT
              ACTIVITIES
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={leadTech}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              LEADING TECHNOLOGY & STREAMLINED PROCESS{" "}
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={multiawards}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              MULTI-AWARDED RECRUITMENT AGENCY FOR OVER 5O YEARS{" "}
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={verifiedemployer}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center text-sm-left">
              ROSTER OF HIGH-QUALITY INDUSTRY-LEADING CLIENTS{" "}
            </b>
          </div>
          <div className="col-md-9 col-lg-4 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left">
              <div className="mx-auto">
                <img
                  className="mx-auto mt-5"
                  src={endtoend}
                  alt="END-TO-END RECRUITMENT"
                  width="200"
                />
              </div>
            </div>
            <b className="offset-top-10 text-dark text-center ">
              END-TO-END RECRUITMENT{" "}
            </b>
          </div>
          <div className="col-md-12 col-lg-12 offset-xl-0 text-center">
            <div className="unit align-items-center unit-spacing-xs unit-xs flex-sm-row text-center text-sm-left mt-5">
              <div className="mx-auto">
                <Link to="/aboutus" className="btn btn-primary">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WhyPeopleChooseUs;
